<template>
  <div class="no-data-box">
    <img src="@/static/img/notice-null.png" class="no-data"/>
    <div class="description">{{text || defaultData}}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    defaultData () {
      return this.$t('common.noneData')
    }
  }
}
</script>

<style>
.no-data-box{
  text-align: center;
  padding-top: 88px;
}
.no-data{
  width: 64px;
  height: 69px;
  margin-bottom: 20px;
}
.description{
  font-size: 16px;
  font-weight: 500;
  color: #C1C1C1;
  line-height: 18px;
}
</style>
